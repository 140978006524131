import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";

const Home = () => {
  return (
    <>
      <PageWrapper>
      <div className="pt-5"></div>
          <Container>
            <Row className="align-items-center position-relative">
              <h1>Privacy Policy</h1>
              <Row className="align-items-center position-relative">
                <p>Markurial was built independently by Charles Fager, a US based iOS developer. This app does not collect or store any user data or information apart from locally storing
                  your pantry items on device.
                  <br/>
                  <br/>
                  No third parties are used to collect or store data or analytics, and no services that may be used to identify or track you are used in this app. No other services get access to the app or your data.
                  <br />
                  <br />
                  This website does not track you or your cookies, and does not ask for any information that can be used to identify you. I do not collect any data with Swipe Pantry or share any data with third parties, or similar.
                </p>
              </Row>
            </Row>
          </Container>
      </PageWrapper>
    </>
  );
};




export default Home;